import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
